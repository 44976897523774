
import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Row, Col, Divider, Skeleton, Modal, message } from "antd";
import {
  BlueSquareButton,
  BlueSquareOulinedButton,
} from "../../../../common/components/button";
import {
  selectSearchListState,
  createReportThunk,
  resetCreateReport,
  resetGetOrder,
  resetCardToken,
  resetPurchaseOrder,
} from "../../pages/list_reports/slice";
import { beautifyCpfCnpj, formatCurrency } from "../../../../utils/formatters";
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../../../utils/stateStatus";
import "./styles.scss";
import { loginSelector } from "../../../login/slice";
import { CreateReportParams } from "../../pages/list_reports/interfaces";

const ProductCartItem = ({ report, target, billingMode }) => {
  return (
    <Row className="item-purchase-row" justify={"space-between"}>
      <Col span={20}>
        <div>
        {report?.case_type_display} para {(target?.product && target?.num_matricula) ? `Matrícula ${target?.num_matricula}` : beautifyCpfCnpj(target?.cpf_cnpj)}
        </div>
      </Col>
      {billingMode !== "mensal" && (
        <Col span={4} style={{ textAlign: "right" }}>
          {formatCurrency(target?.price)}
        </Col>
      )}
    </Row>
  );
};

interface PaymentResultModalProps { 
  onSuccess: (e?: any) => void,
  onFailed: (e?: any) => void,
  onBack: (e?: any) => void,
} 


const ResumeReportModal = ({onSuccess, onFailed, onBack}) => {
  const state = useSelector(selectSearchListState);
  const loginState = useSelector(loginSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.status.createReport === StateStatus.succeeded) {
      dispatch(resetGetOrder());
      dispatch(resetCardToken());
      dispatch(resetPurchaseOrder());
      dispatch(resetCreateReport());
      onSuccess();
    }

    if (state.status.createReport === StateStatus.failed) {
      message.error("Falha ao criar o caso.");
      onFailed();
      dispatch(resetCreateReport());

    }
  }, [state.status.createReport]);

  return (
    <Modal
      className="resume-report-modal"
      title={"Solicitar nova pesquisa"}
      open={true}
      onCancel={onBack}
      footer={[
        <Row
          justify={"space-between"}
          style={{ marginTop: "30px" }}
        >
          <BlueSquareOulinedButton
            key="back"
            onClick={onBack}
            disabled={(
              state.status.createReport === StateStatus.loading 
              || state.status.createReport === StateStatus.succeeded
              || !!state.data.report?.id
            )}
          >
            Voltar
          </BlueSquareOulinedButton>
          <BlueSquareButton
            onClick={() => {
              if(state.data.report && !state.data.report?.id) {
                const data = {
                  client_id: state.data.report.client_id,
                  case_type: state.data.report.case_type,
                  case_name: state.data.report.case_name,
                  case_option: state.data.report.case_option,
                  context: state.data.report.context,
                  services: state.data.report.services,
                  targets: state.data.report.targets,
                  docs_products: state.data.report.docs_products,
                }

                if(state.data.report.context) {
                  data[state.data.report.context] = state.data.report[state.data.report.context]
                }
                
                dispatch(createReportThunk(data))
              }
            }}
            isLoading={state.status.createReport === StateStatus.loading}
            disabled={state.status.createReport === StateStatus.loading}
          >
            {loginState.data.client?.billing_mode !== "mensal" ? (
              "Ir para pagamento"
            ) : (
              "Criar caso"
            )}
            
          </BlueSquareButton>
        </Row>,
      ]}
      width={"900px"}
    >
      <Row justify={"center"}>
        <Col span={18}>
          <Row justify={"center"}>
            <Col>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  marginBottom: "30px",
                  marginTop: "15px",
                }}
              >
                {loginState.data.client?.billing_mode !== "mensal" ? (
                  "ETAPA 2 de 3: Revisão"
                ) : (
                  "ETAPA 2 de 2: Revisão"
                )}
              </div>
              <div style={{ width: "100%" }}>
              {loginState.data.client?.billing_mode !== "mensal" ? (
                "Confira os valores abaixo e prossiga para criação e pagamento do caso."
              ) : (
                "Confira as buscas selecionadas e prossiga para criação do caso."
              )}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row justify={"space-between"} style={{ width: "100%" }}>
            {state.status.calcReportPrice === StateStatus.loading ? (
              <div style={{ width: "100%", height: "150px" }}>
                <Skeleton active />
              </div>
            ) : (
              <>
                {state.data?.report?.case_type !== "docs"
                  ? state.data?.report?.targets?.map((target, idx) => (
                      <Col key={idx} span={24}>
                        <ProductCartItem
                          report={state.data?.report}
                          target={target}
                          billingMode={loginState.data.client?.billing_mode}
                        />
                      </Col>
                    ))
                  : state.data?.report?.docs_products?.map((doc, idx) => (
                      <Col key={idx} span={24}>
                        <ProductCartItem
                          report={state.data?.report}
                          target={doc}
                          billingMode={loginState.data.client?.billing_mode}
                        />
                      </Col>
                    ))}
              </>
            )}
          </Row>
          <br />
          {loginState.data.client?.billing_mode !== "mensal" && (
            <Row justify="start" style={{marginBottom: "100px"}}>
              <Col span={20}>
                <strong>Valor total: </strong>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <span className="font-normal-14-600">
                  {formatCurrency(state.data.report?.price ?? 0)}
                </span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default ResumeReportModal;
