import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { beautifyCpfCnpj } from "../../../../utils/formatters";
import StateStatus from "../../../../utils/stateStatus";
import {
  createFileTargetThunk,
  deleteFileTargetThunk,
  getTargetStatusThunk,
  retryServiceThunk,
  selectReportStatusState,
} from "../../slices";
import RowFileReport from "../report_file_row";
import IconStatus from "../status_icon";
import { ServiceType } from "../../../dashborad/pages/monitoring_page/interfaces";
import { StatusIcon } from "../../../../utils/status";

export const canRequest = (service: ServiceType, services: ServiceType[]) => {
  if (service?.depends_on?.length === 0) return true;

  const readyServices = services
    ?.filter((x) => x.enabled)
    ?.map((s) =>
      service?.depends_on?.includes(s?.service || "")
        ? s?.service_status === "completed"
        : true
    );

  return !readyServices.includes(false);
};

export const getDecimalPart = (number) => {
  const [int = 0, decimal = 0] = parseFloat(number)
    .toFixed(2)
    .split(".")
    .map((num) => parseInt(num));
  return [int, decimal / 100];
};

export const getLastUpdateTime = (service) => {
  const minutes = service.delay_minutes;

  let msg = `Última atualização há`;
  if (service.service_status === "completed") msg = `Completo em`;

  const [days, restDays] = getDecimalPart(minutes / 60 / 24);
  const [hours, restHours] = getDecimalPart(restDays * 24);
  const [mins] = getDecimalPart(restHours * 60);

  if (days > 0) return `${msg} ${days} dia(s) e ${hours} hora(s)`;

  if (hours > 0) return `${msg} ${hours} hora(s) e ${mins} minutos`;

  if (Math.trunc(minutes) > 0) return `${msg} ${Math.trunc(minutes)} minutos`;

  return `${msg} ${Math.trunc(minutes * 60)} segundos`;
};

const TargetStatusCard = ({ target, report }) => {
  const state = useSelector(selectReportStatusState);
  const dispatch = useDispatch();


  return (
    <Row style={{ width: "100%" }} justify={"start"} gutter={[0, 24]}>
      <Col span={24}>
        <div className="report-subtitle">
          <Link to={`/report/${report.id}/target/${target.id}`} target="_blank">
          {beautifyCpfCnpj(target.cpf_cnpj)} - {target.name} <br/><br/>
          <StatusIcon status={target?.status} isAdmUser={true}/>
          </Link>
        </div>
      </Col>
      <Col span={24}>
        <Card>
          {target?.files.map((file, idx) => (
            file.type === "zip_xlsx" || file.type === "pdf" || (
              file.type === "xlsx" && target?.files?.filter((x) => x.type === "zip_xlsx")?.length === 0
            ) || (file.type === "xlsx_part" && (!file.initial_page || file.initial_page <= 1) && target?.files?.filter((x) => x.type === "zip_xlsx")?.length === 0) ? (
            <RowFileReport
              key={idx}
              file={{
                ...file,
                type_display: (file.type === "xlsx" && target?.files.filter(
                  (x) => x.type === "xlsx_part" || (x.type === "zip_xlsx" && x.status !== "completed")
                ).length > 0) || file.type === "xlsx_part" ? "xlsx PAGINADO" : file.type,
                status: (file.type === "xlsx" && target?.files.filter(
                  (x) => x.type === "xlsx_part" || (x.type === "zip_xlsx" && x.status !== "completed")
                ).length > 0) || file.type === "xlsx_part" ? "loading" : file.status
              }}
              filename={`${target.cpf_cnpj?.toUpperCase()}.${file.type}`}
              isRebuildLoading={
                state.status.createFileTarget ===
                  StateStatus.loading &&
                state.data.retryTargetId === target.id
              }
              isDeleteLoading={
                state.status.deleteFileTarget ===
                StateStatus.loading &&
                state.data.deleteFileId === file.id
              }
              onClickRebuild={() => {
                dispatch(
                  createFileTargetThunk({
                    targetId: target.id,
                    type: file.type,
                  })
                );
              }}
              onClickDelete={file.type === "xlsx" && target?.files.filter(
                (x) => x.type === "xlsx_part" || (x.type === "zip_xlsx" && x.status !== "completed")
              ).length > 0 ? undefined : () => {
                dispatch(deleteFileTargetThunk(file.id));
              }}
            />) : null
          ))}
          {["pdf", "xlsx"].map((type, idx) =>
            target?.files?.filter((x) => x.type?.includes(type)).length === 0 ? (
              <RowFileReport
                key={idx}
                file={{ status: "idle", type, type_display: type}}
                filename={null}
                isRebuildLoading={
                  state.status.createFileTarget ===
                  StateStatus.loading &&
                  state.data.retryTargetId === target.id
                }
                isDeleteLoading={false}
                onClickRebuild={() => {
                  dispatch(
                    createFileTargetThunk({
                      targetId: target.id,
                      type,
                    })
                  );
                }}
              />
            ) : null
          )}
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Row
            gutter={[8, 8]}
            justify="start"
            className="service-info"
          >
            {target?.services?.length === 0 || !target?.services ? (
              <Link to="#" onClick={() => dispatch(getTargetStatusThunk(target.id))} >Exibir microserviços</Link>
            ) : null}

            {target?.services
              ?.filter((x) => x.enabled)
              ?.map((service, index) => {
                return (
                  <Col span={24} key={index}>
                    <Row
                      // gutter={[16, 16]}
                      
                      justify="start"
                      className="service-info"
                    >
                      <Col span={4}>
                        <Link
                          to={
                            window.location.hash.includes(service.service)
                              ? ""
                              : `#${service.service}`
                          }
                        >
                          {service.service_display}
                        </Link>
                      </Col>
                      <Col className="msg-info-service" span={12}>
                        {service.service_status === "idle" ? (
                          canRequest(service, target.services) ? (
                            `Apto para executar`
                          ) : (
                            `Aguardando ${Array.from(service.depends_on).join(
                              ", "
                            )}...`
                          )
                        ) : service.service_status === "error" ? (
                          <span>
                            <div>{getLastUpdateTime(service)}</div>
                            <div style={{ color: "red" }}>
                              {service.error_message &&
                              ["credilink", "receita_federal"].includes(
                                service.service
                              )
                                ? service.error_message
                                : ""}
                            </div>
                          </span>
                        ) : service.service_status === "completed" ? (
                          service.block_status !== "completed" ? (
                            <span
                              style={{
                                color:
                                  service.block_status === "error"
                                    ? "red"
                                    : "blue",
                              }}
                            >
                              {service.block_status === "error"
                                ? "Não foi possível gerar HTML"
                                : service.block_status === "loading"
                                ? "Gerando HTML..."
                                : service.block_status === "idle"
                                ? "Criação do HTML não iniciada"
                                : null}
                            </span>
                          ) : (
                            getLastUpdateTime(service)
                          )
                        ) : (
                          getLastUpdateTime(service)
                        )}
                      </Col>
                      <Col span={2}>
                        <span
                          className={`status-icon status-${service.service_status}`}
                        >
                          {<IconStatus status={service.service_status} />}
                        </span>
                      </Col>
                      <Col span={2}>
                        {(["error", "idle"].includes(service.service_status) ||
                          (service.service_status !== "completed" &&
                            service.delay_minutes >= 15)) &&
                        canRequest(service, target.services) ? (
                          <Button
                            icon={<SyncOutlined />}
                            size={"small"}
                            loading={
                              state.status.retryService ===
                                StateStatus.loading &&
                              state.data.retryServiceId ===
                                service.id
                            }
                            onClick={() => {
                              dispatch(retryServiceThunk(service.id));
                            }}
                          >
                            {service.service_status === "idle"
                              ? "Executar"
                              : "Retentar"}
                          </Button>
                        ) : null}

                        {service.service_status === "completed" &&
                        service.block_status !== "completed" ? (
                          <Button
                            icon={<SyncOutlined />}
                            size={"small"}
                            loading={
                              state.status.retryService ===
                                StateStatus.loading &&
                              state.data.retryServiceId ===
                                service.id
                            }
                            onClick={() => {
                              dispatch(retryServiceThunk(service.id));
                            }}
                          >
                            {service.block_status === "idle"
                              ? "Criar HTML"
                              : "Retentar HTML"}
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                    <div
                      className="service-data"
                      style={{
                        display: window.location.hash.includes(service.service)
                          ? "block"
                          : "none",
                      }}
                    >
                      <div>
                        <label>Dossier ID:</label>
                        <span>{service.dossier_id}</span>
                      </div>
                      <div>
                        <label>Service ID:</label>
                        <span>{service.id}</span>
                      </div>
                      <div>
                        <label>Target ID:</label>
                        <span>{target.id}</span>
                      </div>
                      <div>
                        <label>Report ID:</label>
                        <span>{report.id}</span>
                      </div>
                      <br />
                      <div>
                        <label>Depende de:</label>
                        <span>{Array.from(service.depends_on).join(", ")}</span>
                      </div>
                      <div>
                        <label>Criado em:</label>
                        <span>{service.created_at}</span>
                      </div>
                      <div>
                        <label>Atualizado em:</label>
                        <span>{service.updated_at}</span>
                      </div>
                      <div>
                        <label>Erros:</label>
                        <div style={{ color: "red" }}>
                          {service.error_message ?? "-"}
                        </div>
                      </div>
                      <br />
                      <Link
                        to={`/report/${report.id}/target/${target.id}#${service.service}`}
                        target="_blank"
                      >
                        {" "}
                        Visualizar Caso{" "}
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default TargetStatusCard;
