import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createReport,
  listReports,
  getReport,
  calcReportPrice,
  createOrder,
  editOrder,
  getOrder,
} from "./service";
import StateStatus from "../../../../utils/stateStatus";
import { createCreditCardToken } from "../../../../utils/pagarme";
import { CalcReportPriceParams, CreateOrderParams, CreateReportParams, EditOrderParams, ListReportsParams, ReportsState, CustomErrorType } from "./interfaces";


// Thunks
export const getListReportsThunk = createAsyncThunk(
  "reports/list-reports",
  async ({ page, pageSize, filter, onlyOwner, status }: ListReportsParams) => {
    const response = await listReports({
      pagination: pageSize ?? 15,
      page,
      filter,
      only_owner: onlyOwner ?? true,
      status,
    });

    return {
      pageSize: pageSize ?? 15,
      onlyOwner: onlyOwner ?? true,
      status,
      filter,
      ...response.data,
    };
  }
);

export const calcReportPriceThunk = createAsyncThunk(
  "reports/calc-price",
  async (data: CalcReportPriceParams) => {
    const response = await calcReportPrice(data);
    return response.data;
  }
);

export const createReportThunk = createAsyncThunk(
  "reports/create",
  async (data: CreateReportParams) => {
    const response = await createReport(data);
    return response.data;
  }
);

export const getReportThunk = createAsyncThunk(
  "reports/get",
  async (reportId: string) => {
    const response = await getReport(reportId);
    return response.data;
  }
);

export const getOrderThunk = createAsyncThunk(
  "reports/get-order",
  async (code: string) => {
    const response = await getOrder(code);
    return response.data;
  }
);

export const purchaseThunk = createAsyncThunk(
  "reports/purchase",
  async (data: CreateOrderParams) => {
    try {
      const response = await createOrder(data);
      return response.data;
    } catch (err: any) {
      const message = err.response?.data?.msg ?? "Não foi possível realizar solicitação.";

      const customError: CustomErrorType = {
          message,
          name: "Api Error",
          data: err.response?.data,
      };
      
      throw customError;
    }
  }
);

export const createCreditCardTokenThunk = createAsyncThunk(
  "reports/card-token",
  async (data: any) => {
    const token = await createCreditCardToken(data);
    return token;
  }
);

export const editOrderThunk = createAsyncThunk(
  "reports/edit-order",
  async ({ id, data }: EditOrderParams) => {
    try {
      const response = await editOrder(id, data);
      return response.data;
    } catch (err: any) {
      const message = err.response?.data?.msg ?? "Não foi possível realizar solicitação.";

      const customError: CustomErrorType = {
          message,
          name: "Api Error",
          data: err.response?.data,
      };
      
      throw customError;
    }
  }
);

// Estado inicial
const initialState: ReportsState = {
  status: {
    listReports: StateStatus.idle,
    createReport: StateStatus.idle,
    calcReportPrice: StateStatus.idle,
    getReport: StateStatus.idle,
    getOrder: StateStatus.idle,
    purchase: StateStatus.idle,
    editOrder: StateStatus.idle,
    cardToken: StateStatus.idle,
  },
  data: {
    cardToken: null,
    reports: {
      items: null,
      pagination: {
        page: 0,
        totalCount: 0,
        totalPages: 1,
      },
      filters: {
        filter: "",
        onlyOwner: true,
        status: null,
      },
    },
    report: null,
    order: null,
  },
  errMessage: {
    listReports: null,
    getReport: null,
    createReport: null,
    calcReportPrice: null,
    getOrder: null,
    purchase: null,
    editOrder: null,
    cardToken: null,
  },
};

// Slice para gerenciamento do estado
export const listReportsSlice = createSlice({
  name: "listReports",
  initialState,
  reducers: {
    resetCalcReportPrice: (state) => {
      state.status.calcReportPrice = StateStatus.idle;
    },
    resetCreateReport: (state) => {
      state.status.createReport = StateStatus.idle;
    },
    resetPurchaseOrder: (state) => {
      state.status.purchase = StateStatus.idle;
      state.status.editOrder = StateStatus.idle;
    },
    resetGetOrder: (state) => {
      state.data.order = null;
      state.status.getOrder = StateStatus.idle;
    },
    resetCardToken: (state) => {
      state.data.cardToken = null;
      state.status.cardToken = StateStatus.idle;
    },
  },
  extraReducers: (builder) => {
    // Thunks para relatórios
    builder
    // getReportThunk
    .addCase(getReportThunk.pending, (state) => {
      state.status.getReport = StateStatus.loading;
    })
    .addCase(getReportThunk.fulfilled, (state, action) => {
        state.data.report = action.payload;
        state.status.getReport = StateStatus.succeeded;
    })
    .addCase(getReportThunk.rejected, (state, action) => {
        state.status.getReport = StateStatus.failed;
        state.errMessage.getReport = action.error.message || null;
    })
      .addCase(getListReportsThunk.pending, (state) => {
        state.status.listReports = StateStatus.loading;
      })
      .addCase(getListReportsThunk.fulfilled, (state, { meta, payload }) => {
        state.status.listReports = StateStatus.succeeded;
        state.data.reports.items = payload.items;
        state.data.reports.pagination.totalCount = payload.pagination.total_count;
        state.data.reports.pagination.totalPages = payload.pagination.total_pages;
        state.data.reports.pagination.pageSize = payload.pageSize;
        state.data.reports.pagination.page = payload.pagination.current_page;
        state.data.reports.filters.filter = payload.filter;
        state.data.reports.filters.onlyOwner = payload.onlyOwner;
        state.data.reports.filters.status = payload.status;
      })
      .addCase(getListReportsThunk.rejected, (state, { meta, payload, error }) => {
        state.status.listReports = StateStatus.failed;
        state.errMessage.listReports = error.message || null;
      });

    // Thunks para criação de relatórios
    builder
      .addCase(createReportThunk.pending, (state) => {
        state.status.createReport = StateStatus.loading;
      })
      .addCase(createReportThunk.fulfilled, (state, { meta, payload }) => {
        state.status.createReport = StateStatus.succeeded;
        state.data.report = payload;
      })
      .addCase(createReportThunk.rejected, (state, action) => {
        state.status.createReport = StateStatus.failed;
        state.errMessage.createReport = action.error.message;
      });

    // Thunks para cálculo de preço de relatório
    builder
      .addCase(calcReportPriceThunk.pending, (state) => {
        state.status.calcReportPrice = StateStatus.loading;
      })
      .addCase(calcReportPriceThunk.fulfilled, (state, { meta, payload }) => {
        state.status.calcReportPrice = StateStatus.succeeded;
        const contextData = meta.arg[meta.arg.context]
        state.data.report = { ...payload, [meta.arg.context]: contextData};
      })
      .addCase(calcReportPriceThunk.rejected, (state, action) => {
        state.status.calcReportPrice = StateStatus.failed;
        state.errMessage.calcReportPrice = action.error.message;
      })

      // getOrderThunk
      .addCase(getOrderThunk.pending, (state, { meta, payload }) => {
        state.status.getOrder = StateStatus.loading;
      })
      .addCase(getOrderThunk.fulfilled, (state, { meta, payload }) => {
        state.data.order = payload;
        state.status.getOrder = StateStatus.succeeded;
      })
      .addCase(getOrderThunk.rejected, (state, { meta, payload, error }) => {
        state.status.getOrder = StateStatus.failed;
        state.errMessage.getOrder = error.message;
      })

      // purchase thunk
      .addCase(purchaseThunk.pending, (state) => {
        state.status.purchase = StateStatus.loading;
      })
      .addCase(purchaseThunk.fulfilled, (state, { meta, payload }) => {
        state.status.purchase = StateStatus.succeeded;
        state.data.order = payload;
      })
      .addCase(purchaseThunk.rejected, (state, { meta, payload, error }) => {
        state.status.purchase = StateStatus.failed;
        state.errMessage.purchase = error.message;
      })

    // createCreditCardTokenThunk
      .addCase(createCreditCardTokenThunk.pending, (state) => {
        state.status.cardToken = StateStatus.loading;
      })
      .addCase(createCreditCardTokenThunk.fulfilled, (state, { meta, payload }) => {
        state.status.cardToken = StateStatus.succeeded;
        state.data.cardToken = payload;
      })
      .addCase(createCreditCardTokenThunk.rejected, (state) => {
        state.status.cardToken = StateStatus.failed;
        state.errMessage.cardToken = "Não foi possível gerar o token do cartão";
      })

      // editOrderThunk
      .addCase(editOrderThunk.pending, (state) => {
        state.status.editOrder = StateStatus.loading;
      })
      .addCase(editOrderThunk.fulfilled, (state, { meta, payload }) => {
        state.status.editOrder = StateStatus.succeeded;
        state.data.order = payload;
      })
      .addCase(editOrderThunk.rejected, (state, { meta, payload, error }) => {
        state.status.editOrder = StateStatus.failed;
        state.errMessage.editOrder = error?.message;
      });
  },
});

export const {
  resetCalcReportPrice,
  resetCreateReport,
  resetPurchaseOrder,
  resetGetOrder,
  resetCardToken,
} = listReportsSlice.actions;

export const selectSearchListState = (state: { listReports: ReportsState }) => state.listReports;