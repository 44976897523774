import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAccount } from "./service";
import StateStatus from "../../utils/stateStatus";
import { getProfileResponse } from "../login/interfaces";
import { CreateAccountData as CreateAccountParams, CreateAccountState, CustomError as CustomErrorType } from "./intrfaces";

export const createAccountThunk = createAsyncThunk<getProfileResponse, CreateAccountParams>(
    "createAccount/create",
    async (data) => {
        try {
            const response = await createAccount(data);

            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);

            return response.data;
        } catch (err: any) {
            const message = err.response?.data?.msg ?? "Não foi possível criar usuário.";

            const customError: CustomErrorType = {
                message,
                name: "Api Error",
                data: err.response?.data,
            };
            
            throw customError;
        }
    }
);

const initialState: CreateAccountState = {
    status: {
        create: StateStatus.idle,
    },
    errMessage: null,
};

// Slice tipado
export const createAccountSlice = createSlice({
    name: "createAccount",
    initialState,
    reducers: {
        resetCreateAccount: (state) => {
            state.status.create = StateStatus.idle;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAccountThunk.pending, (state) => {
                state.status.create = StateStatus.loading;
            })
            .addCase(createAccountThunk.rejected, (state, { error }) => {
                state.status.create = StateStatus.failed;
                console.log({error})
                state.errMessage = error.message || null;
            })
            .addCase(createAccountThunk.fulfilled, (state) => {
                state.status.create = StateStatus.succeeded;
            });
    },
});

// Exportações
export const { resetCreateAccount } = createAccountSlice.actions;

export const createAccountSelector = (state: { createAccount: CreateAccountState }) => state.createAccount;
