
import React from "react";
import { Col, Row } from "antd";
import { ServicesEnum } from "../../../../../../utils/services";
import { TabContentPartnership } from "../receita_federal_partnerships";

const TabContentEmpresarioInvividual = () => {

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <TabContentPartnership sectionKey={ServicesEnum.receita_federal.empresarios_individuais_confirmados_do_cpf.name} />
        </Col>
        <Col span={24}>
          <TabContentPartnership sectionKey={ServicesEnum.receita_federal.empresarios_individuais_possiveis_do_cpf.name} />
        </Col>
      </Row>
    </div>
  );
};

export { TabContentEmpresarioInvividual };
