import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import { TabContainer } from "../../components/tab-container";
import { Link } from "react-router-dom";
import { ServicesEnum } from "../../../../../../utils/services";
// @ts-ignore
import { ReactComponent as CompanyIcon } from "../../../../../../common/images/company.svg";
import Icons from "../../../../../../common/components/Icons";
import { TabContentEcosystem } from "../ecosystem";


const TabContentInfoCPF = () => {
  const serviceKey = ServicesEnum.credilink.name;
  const sectionKey = ServicesEnum.credilink.info_cpf.name;

  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;
  const person = sectionData?.data?.items[0];

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const gooleMapsLink = (addressFormatted) => {
    return `https://www.google.com/maps/search/?api=1&query=${addressFormatted}`
  }

  const columns = [
    {
      title: "NOME",
      dataIndex: "name",
    },
    {
      title: "DATA DE NASCIMENTO",
      dataIndex: "birthdate",
    },
    {
      title: "IDADE",
      dataIndex: "age",
    },
    {
      title: "NOME DA MÃE",
      dataIndex: "mother_name",
    },
    {
      title: "STATUS RECEITA",
      dataIndex: "status_receita",
      render: (value, record) => {
        let status;

        switch (value) {
          case 'REGULAR':
            status = <div style={{backgroundColor: 'green', color: 'white', fontWeight: 'bold', padding: '3px 10px', borderRadius: '5px', minWidth: '35px', textAlign:'center'}}>{value}</div>
            break
          case '':
            status =  <div style={{backgroundColor: 'red', color: 'white', fontWeight: 'bold', padding: '3px 10px', borderRadius: '5px', minWidth: '35px', textAlign:'center'}}>{value}</div>
            break
          
          default:
            status = <div style={{backgroundColor: '#F49D1A', color: 'white', fontWeight: 'bold', padding: '3px 10px', borderRadius: '5px', minWidth: '35px', textAlign:'center'}}>{value}</div>
        }

        return (  
          <Row justify={"space-between"}>
            <Col>{status}</Col>
          </Row>
        )
      },
    },
    {},
    {
      title: "TELEFONE(S)",
      dataIndex: "phones",
      render: (values, record) => {
        return values?.map((value, idx) => (
          <div key={idx}>
            {value}
          </div>
        ));
      },
    },
    {
      title: "EMAIL(S)",
      dataIndex: "emails",
      render: (values, record) => {
        return values?.map((value, idx) => (
          <div key={idx}>
            {value.email}
          </div>
        ));
      },
    },
    {
      title: "ENDEREÇO(S)",
      dataIndex: "address",
      render: (values, record) => {
        return values?.map((value, idx) => (
          <div key={idx}>{value} <Link to={gooleMapsLink(value)} target="__blank"><Icons icon="open-link"/></Link></div>
        ));
      },
    },
  ];

  function getValueByKey(key, obj) {
    return key?.split(".")?.reduce((o, i) => (o ? o[i] : undefined), obj);
  }

  return (<>
    <TabContainer
      icon={<Icons icon="info-cpf" />}
      title={"Dados da Receita Federal"}
    >
      <Row>
        <Col span={24}>
          <Row>
            <p style={{ fontWeight: "500", fontSize: "20px" }}>{getValueByKey('name', person)} - {getValueByKey('cpf_cnpj', person)}</p>
          </Row>
          <Row>
            {columns.map((item, idx) => (
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="service-label-value">
                {item?.title ? (<>
                  <label>{item?.title}</label>
                  {item?.render ? (
                    item?.render(
                      getValueByKey(item?.dataIndex, person),
                      person
                    )
                  ) : (
                    <div>{getValueByKey(item?.dataIndex, person) ?? "-"}</div>
                  )}
                </>) : null}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </TabContainer>
    <TabContentEcosystem />
    </>
  );
};

export { TabContentInfoCPF };
