export const ServicesEnum = {
    veiculos: {
        name: "veiculos",
        veiculos: {name: "veiculos", label: "Veículos"},
    },
    receita_federal: {
        name: "receita_federal",
        info_cnpj: {name: "info_cnpj", label: "Dados cadastrais"},
        grupo_economico_cnpj: {name: "grupo_economico_cnpj", label: "Grupo econômico"},
        empresas_cpf_cnpj_e_socio: {name: "empresas_cpf_cnpj_e_socio", label: "Pessoas Jurídicas nas quais o(a) pesquisado(a) tem participação ou relação"},
        filiais_do_cnpj: {name: "filiais_do_cnpj", label: "Filiais"},
        socios_do_cnpj: {name: "socios_do_cnpj", label: "Sócios"},
        empresarios_individuais_confirmados_do_cpf: {name: "empresarios_individuais_confirmados_do_cpf", label: "Empresários Individuais confirmados"},
        empresarios_individuais_possiveis_do_cpf: {name: "empresarios_individuais_possiveis_do_cpf", label: "Possíveis Empresários Individuais"},
    },
    processos: {
        name: "processos", 
        processos_em_tramitacao_por_cpf_cnpj: {name: "processos_em_tramitacao_por_cpf_cnpj", label: "Processos em tramitação por CPF/CNPJ (Em tramitação)"},
        processos_em_tramitacao_por_nome: {name: "processos_em_tramitacao_por_nome", label: "Processos por nome (Em tramitação)"},
        processos_inativos_por_nome: {name: "processos_inativos_por_nome", label: 'Prcessos por nome (Arquivados)'},
        processos_inativos_por_cpf_cnpj: {name: "processos_inativos_por_cpf_cnpj", label: 'Processos por CPF/CNPJ (Arquivados)'},
        processos_para_aquisicao_copia: {name: "processos_para_aquisicao_copia", label: 'Processos relevantes'},
        averiguacao_processos: {name: "averiguacao_processos", label: 'Averiguação sobre os processos'},
    },
    inpi: {
        name: "inpi",
        marcas: {name: "marcas", label: "marcas"},
        patentes: {name: "patentes", label: "patentes"},
    },
    cenprot: {
        name: "cenprot",
        protestos: {name: "protestos", label: "Protestos"},
    },
    jucesp: {
        name: "jucesp",
        empresas: {name: "empresas", label: "Atos societários"},
        arquivamentos: {name: "arquivamentos", label: "Arquivamentos"},
    },
    sintegra: {
        name: "sintegra",
        sintegra_pf: {name: "sintegra_pf", label: "Sintegra"},
        sintegra_pj: {name: "sintegra_pj", label: "Sintegra"},
    },
    boavista: {
        name: "boavista",
        scores: {name: "scores", label: "scores"},
        protestos: {name: "protestos", label: "protestos"},
        resumo_titulos_protestados: {name: "resumo_titulos_protestados", label: "resumo_titulos_protestados"},
        debitos: {name: "debitos", label: "debitos"},
        resumo_debitos: {name: "resumo_debitos", label: "resumo_debitos"},
        decisao: {name: "decisao", label: "decisao"},
        identificacao_pj: {name: "identificacao_pj", label: "identificacao_pj"},
        identificacao_pf: {name: "identificacao_pf", label: "identificacao_pf"},
    },
    pep: {
        name: "pep",
        pep: {name: "pep", label: "PEP"},
    },
    gnews: {
        name: "gnews",
        noticias: {name: "noticias", label: "Notícias"},
    },
    socialmedia: {
        name: "socialmedia",
        socialmedias: {name: "socialmedias", label: "Redes Sociais"},
    },
    whois: {
        name: "whois",
        dominios: {name: "dominios", label: "Sites"},
    },
    tst: {
        name: "tst",
        debitos_trabalhistas: {name: "debitos_trabalhistas", label: "Certidão de débitos trabalhistas"},
    },
    ecosystem: {
        name: "ecosystem",
        relationships: {name: "relationships", label: "Relacionamentos"},
    },
    credilink: {
        name: "credilink",
        info_cpf: {name: "info_cpf", label: "Dados cadastrais"},
        info_cnpj: {name: "info_cnpj", label: "Dados cadastrais"},
    },
    parecer: {
        name: "parecer",
        parecer: {name: "parecer", label: "Parecer"},
    },
    imoveis: {
        name: "imoveis",
        imoveis: {name: "imoveis", label: "Imóveis"},
    },
}  