
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";

const TabContentTST = () => {
  const serviceKey = ServicesEnum.tst.name;
  const sectionKey = ServicesEnum.tst.debitos_trabalhistas.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const columns = [
    {
      title: "CERTIDÕES TRABALHISTAS",
      responsive: ["xs",],
      dataIndex: "domain",
      render: (_, record) => {
        return (<>
          <div><label>CÔRTE</label>{record.court}</div>
          <div><label>NÚMERO</label>{record.number}</div>
        </>)
      }
    },
    {
      title: "CÔRTE",
      responsive: ["lg", "md"],
      dataIndex: "court",
    },
    {
      title: "NÚMERO",
      responsive: ["lg", "md"],
      dataIndex: "number",
    },
  ];

  return (
    <TabContainer
      icon={<Icons icon={"tst"} />}
      title={"Certidão de débitos trabalhistas"}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de certidões</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record) => new Date().getTime().toString()}
            // @ts-ignore
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui certidão de débitos trabalhistas'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentTST };
