import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import StateStatus from "../../utils/stateStatus";
import { createReportUnauth } from "./service";
import { CreateReportUnauthData, CreateReportUnauthResponse, CreateReportUnauthState } from "./interfaces";


// Thunk tipado
export const createReportUnauthThunk = createAsyncThunk<CreateReportUnauthResponse, CreateReportUnauthData>(
    'createReportUnauth/create',
    async (data) => {
        const response = await createReportUnauth(data)
        return response.data;
    }
);

// Estado inicial tipado
const initialState: CreateReportUnauthState = {
    status: {
        createReportUnauth: StateStatus.idle,
    },
    data: {
        redirect_url: null,
    },
    errMessage: {
        createReportUnauth: null,
    }
};

// Slice tipado
export const createReportUnauthSlice = createSlice({
    name: 'createReportUnauthSlice',
    initialState,
    reducers: {
        resetCreateReport: (state) => {
            state.status.createReportUnauth = StateStatus.idle;
            state.data.redirect_url = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createReportUnauthThunk.pending, (state) => {
                state.status.createReportUnauth = StateStatus.loading;
            })
            .addCase(createReportUnauthThunk.fulfilled, (state, action) => {
                state.status.createReportUnauth = StateStatus.succeeded;
                state.data.redirect_url = action.payload.redirect_url;
            })
            .addCase(createReportUnauthThunk.rejected, (state, action) => {
                state.status.createReportUnauth = StateStatus.failed;
                state.errMessage.createReportUnauth = action.error.message || null;
            });
    },
});

// Exportações
export const { resetCreateReport } = createReportUnauthSlice.actions;

export const selectCreateReportUnauthState = (state: { createReportUnauth: CreateReportUnauthState }) => state.createReportUnauth;
