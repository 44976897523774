import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const SurveyPage = () => {
  const params = useParams();


  useEffect(() => {
    window.location.replace(`https://printpdf.report.inquest.tech/#/survey/${params.surveyId}`)
  }, [params.surveyId]);


  return (
    <></>
  );
};

export default SurveyPage;
