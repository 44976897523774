
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";

const TabContentImoveis = () => {
  const serviceKey = ServicesEnum.imoveis.name;
  const sectionKey = ServicesEnum.imoveis.imoveis.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const columns = [
    {
      title: "MATRÍCULAS DE IMOVEIS",
      responsive: ["xs",],
      dataIndex: "domain",
      render: (_, record) => {
        return (<>
          <div><label>LOCALIDADE</label> {record.localidade}</div>
          <div><label>CARTÓRIO</label> {record.nome_cartorio}</div>
          <div><label>MATRÍCULA</label> {record.matricula}</div>
        </>)
      }
    },
    {
      title: "LOCALIDADE",
      responsive: ["lg", "md"],
      dataIndex: "localidade",
    },
    {
      title: "CARTÓRIO",
      responsive: ["lg", "md"],
      dataIndex: "nome_cartorio",
    },
    {
      title: "MATRÍCULA",
      responsive: ["lg", "md"],
      dataIndex: "matricula",
    },
  ];

  return (
    <TabContainer
      icon={null}
      title={"Imóveis"}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de imóveis</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            // @ts-ignore
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui imóveis'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentImoveis };
