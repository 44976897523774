
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
import { Link } from "react-router-dom";

const TabContentSocialmedia = () => {
  const serviceKey = ServicesEnum.socialmedia.name;
  const sectionKey = ServicesEnum.socialmedia.socialmedias.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const columns = [
    {
      title: "MÍDIAS SOCIAIS",
      responsive: ["xs",],
      dataIndex: "domain",
      render: (_, record) => {
        return (<>
          <div><label>MÍDIA SOCIAL</label>{record.title}</div>
          <div><label>LINK</label>{record.link}</div>
        </>)
      }
    },
    {
      title: "MÍDIA SOCIAL",
      responsive: ["lg", "md"],
      dataIndex: "title",
    },
    {
      title: "LINK",
      responsive: ["lg", "md"],
      dataIndex: "link",
      render: (value) => <Link to={value} target="_blank">{value}</Link>
    },
  ];

  return (
    <TabContainer
      icon={<Icons icon={"midias-sociais"} />}
      title={"Mídias sociais"}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de mídias sociais</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            // @ts-ignore
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui mídias sociais'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentSocialmedia };
