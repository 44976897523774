import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Pagination, Row, Table } from "antd";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
// @ts-ignore
import { ReactComponent as CompanyIcon } from "../../../../../../common/images/company.svg";
import { formatCurrency } from "../../../../../../utils/formatters";
import StateStatus from "../../../../../../utils/stateStatus";
import { LoadingSkeleton } from "../../../../../../common/components/loading";

const MAP_SECTIONS = {
  empresas_cpf_cnpj_e_socio: {
    title:
      "Pessoas Jurídicas nas quais o(a) pesquisado(a) tem participação ou relação",
  },
  filiais_do_cnpj: { title: "Filiais" },
  empresarios_individuais_confirmados_do_cpf: {
    title: "Empresários individuais confirmados",
  },
  empresarios_individuais_possiveis_do_cpf: {
    title: "Possíveis empresários individuais",
  },
};

interface TabContentPartnershipProps {
  sectionKey: string;
}

export const SituacaoEmpresa = ({value, record}) => {
  let color;

  switch (value) {
    case 'ATIVA':
      color =  'green'
      break
    case 'BAIXADA':
      color =  'red'
      break
    
    default:
      color = '#F49D1A'
  }

  return (
    <Row justify={"space-between"}>
      <Col>
        <div style={{
          backgroundColor: color, 
          display: 'inline-block',
          color: 'white', 
          fontWeight: 'bold', 
          padding: '3px 10px', 
          borderRadius: '5px', 
          minWidth: '35px', 
          textAlign:'center',
          marginBottom: '5px',
        }}>{value}</div>
        <p>{record?.desc_motivo_cadastral?.descricao !== 'SEM MOTIVO' ? record?.desc_motivo_cadastral?.descricao : null}</p>
      </Col>
    </Row>  
  )
}

const TabContentPartnership: FunctionComponent<TabContentPartnershipProps> = ({
  sectionKey,
}) => {
  const serviceKey = ServicesEnum.receita_federal.name;

  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services
    ? state.data.target?.services[serviceKey]
    : null;
  const sectionData =
    serviceData?.sections && serviceData.sections[sectionKey]
      ? serviceData.sections[sectionKey]
      : null;
  const companies = sectionData?.data?.items;

  useEffect(() => {
    if (
      serviceData?.dossier_id &&
      serviceData?.service_status === "completed" &&
      !sectionData?.data?.pagination?.current_page
    ) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);

  const columns = [
    {
      title: "NOME FANTASIA",
      dataIndex: "nome_fantasia",
    },
    {
      title: "DATA DE ABERTURA",
      dataIndex: "data_inicio_atividade",
    },
    {
      title: "NATUREZA JURÍDICA",
      dataIndex: "extra.desc_natureza_juridica.descricao",
    },
    {
      title: "SITUAÇÃO",
      dataIndex: "situacao",
      render: (value, record) => {
        return <SituacaoEmpresa value={value} record={record} />
      },
    },
    {
      title: "SITUAÇÃO ESPECIAL",
      dataIndex: "situacao_especial",
    },
    {
      title: "OPTANTE PELO SIMPLES",
      dataIndex: "simples.opcao_simples",
      render: (value, record) => {
        return value === "S" ? <div>SIM</div> : <div>NÃO</div>;
      },
    },
    {
      title: "CAPITAL SOCIAL",
      dataIndex: "extra.capital_social_da_empresa",
      render: (value, record) => {
        return formatCurrency(value);
      },
    },
    {},
    {
      title: "CNAE FISCAL",
      dataIndex: "desc_cnae_fiscal",
      render: (value, record) => {
        return value ? (
          <div>
            {value.codigo} - {value.descricao}
          </div>
        ) : null;
      },
    },
    {
      title: "CNAE(S) SECUNDÁRIA(S)",
      dataIndex: "cnae_secundaria",
      render: (values, record) => {
        return values?.map((value, idx) => (
          <div key={idx}>
            {value.codigo} - {value.descricao}
          </div>
        ));
      },
    },
  ];

  const columnsSocios = [
    {
      title: "NOME",
      responsive: ["lg", "md"],
      dataIndex: "nome_socio",
    },
    {
      title: "DOCUMENTO",
      responsive: ["lg", "md"],
      dataIndex: "cnpjcpf_do_socio",
    },
    {
      title: "QUALIFICAÇÃO",
      responsive: ["lg", "md"],
      dataIndex: "qualificacao_socio",
      render: (value, record) => {
        return <div>{value?.descricao ?? "-"}</div>;
      },
    },
    {
      title: "PARTICIPAÇÃO",
      responsive: ["lg", "md"],
      dataIndex: "participation",
      render: (value, record) => {
        return <div>{value ?? "-"}</div>;
      },
    },
    {
      title: "ENTRADA",
      responsive: ["lg", "md"],
      dataIndex: "data_entrada_sociedade",
    },
  ];

  useEffect(() => {
    if (sectionData?.status === StateStatus.succeeded) {
      const serviceData = state.data.target?.services[serviceKey] ?? null;
      const sectionData = serviceData ? serviceData.sections[sectionKey] : null;
      
      companies.map((company, companyIdx) => {
        if (company?.socios === undefined || company?.socios?.length === 0) {
          dispatch(
            getServiceDataThunk({
              service: serviceKey,
              section: ServicesEnum.receita_federal.socios_do_cnpj.name,
              filterBy: { cnpj: company.cnpj },
              dossierId: serviceData?.dossier_id,
              page: 1,
              subItemsMap: {
                sectionKey,
                itemIndex: companyIdx,
                itemKey: "socios",
              },
            })
          );
        }
      });
    }
  }, [sectionData?.status]);

  function getValueByKey(key, obj) {
    return key.split(".").reduce((o, i) => (o ? o[i] : undefined), obj);
  }

  function maskPhone(ddd, phone) {
    if (!ddd || ddd === "0000" || ddd === "0") {
      return null;
    }
    if (phone.length === 8) {
      return `(${ddd}) ${phone.slice(0, 4)}-${phone.slice(4)}`;
    }

    return `(${ddd}) ${phone.slice(0, 5)}-${phone.slice(5)}`;
  }

  return (
    <TabContainer
      icon={<CompanyIcon />}
      title={MAP_SECTIONS[sectionKey]?.title}
    >
      {sectionData?.status === StateStatus.loading ? (
        <LoadingSkeleton />
      ) : sectionData?.status === StateStatus.succeeded &&
        sectionData?.data?.pagination?.total_items > 0 ? (
        <>
          {companies.map((company: any, companyIdx) => {
            return (
              <div key={company.cnpj}>
                <Row justify={"start"}>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Row align={"middle"}>
                      <div>
                        <div
                          style={{
                            width: 0,
                            height: 0,
                            borderTop: "10px solid transparent",
                            borderBottom: "10px solid transparent",
                            borderLeft: "15px solid #007AFF",
                            marginRight: "10px",
                            display: "inline-block",
                          }}
                        ></div>
                        <span style={{ fontWeight: "500", fontSize: "20px" }}>
                          {getValueByKey("razao_social", company)} -{" "}
                          {getValueByKey("cnpj", company)}
                        </span>
                      </div>
                    </Row>
                    <br />
                    <Row>
                      {columns.map((item, idx) => (
                        <Col xs={24} sm={24} md={12} lg={12}>
                          {item?.title ? (
                            <div className="service-label-value">
                              <label>{item?.title}</label>
                              {item?.render ? (
                                <div>
                                  {item?.render(
                                    getValueByKey(item?.dataIndex, company),
                                    company
                                  )}
                                </div>
                              ) : item?.dataIndex ? (
                                <div>
                                  {getValueByKey(item?.dataIndex, company) ??
                                    "-"}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <Row>
                      <Col span={24}>
                        <div className="service-label-value">
                          <label>ENDEREÇO</label>
                          <div>{company?.address_formatted}</div>
                          <iframe
                            style={{
                              border: "none",
                              width: "320px",
                              height: "200px",
                            }}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBloQgYAwgxRmK4wPpBIlyI-u5dxL7DaJc&q=${company?.address_formatted}&zoom=16 allowfullscreen`}
                          />
                        </div>
                      </Col>
                      <br />
                      <br />
                      <Col span={24}>
                        <div className="service-label-value">
                          <label>CONTATOS</label>
                          <div>
                            {company?.correio_eletronico ||
                              "E-mail não cadastrado"}
                          </div>
                          <div>
                            {maskPhone(company?.ddd_1, company?.telefone_1) ||
                              "Telefone não cadastrado"}
                          </div>
                          <div>
                            {maskPhone(company?.ddd_2, company?.telefone_2)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="service-label-value">
                      <label>QUADRO SOCIETÁRIO</label>
                      <div>
                        <Table
                          // @ts-ignore
                          columns={columnsSocios}
                          loading={
                            serviceData?.section &&
                            serviceData?.section[
                              ServicesEnum.receita_federal.socios_do_cnpj.name
                            ]?.status === StateStatus.loading
                          }
                          dataSource={company?.socios?.data?.items || []}
                          locale={{
                            emptyText:
                              "Esta empresa não possui quadro societário.",
                          }}
                          pagination={{
                            showSizeChanger: false,
                            current:
                              company?.socios?.data?.pagination?.current_page,
                            pageSize:
                              company?.socios?.data?.pagination?.per_page,
                            total:
                              company?.socios?.data?.pagination?.total_items,
                            onChange: (page, pageSize) => {
                              dispatch(
                                getServiceDataThunk({
                                  service: serviceKey,
                                  section:
                                    ServicesEnum.receita_federal.socios_do_cnpj
                                      .name,
                                  filterBy: { cnpj: company.cnpj },
                                  dossierId: serviceData?.dossier_id,
                                  page: page,
                                  subItemsMap: {
                                    sectionKey,
                                    itemIndex: companyIdx,
                                    itemKey: "socios",
                                  },
                                })
                              );
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <hr
                    style={{
                      color: "#7D7D7D",
                      width: "100%",
                      margin: "40px 0px",
                    }}
                  />
                </Row>
              </div>
            );
          })}
          <Row justify={"end"}>
            <Pagination
              showSizeChanger={false}
              current={sectionData?.data?.pagination?.current_page}
              pageSize={sectionData?.data?.pagination?.per_page}
              total={sectionData?.data?.pagination?.total_items}
              onChange={(page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              }}
            />
          </Row>
        </>
      ) : (
        <div style={{ textAlign: "center", width: "100%", marginTop: "15px" }}>
          Não foram encontrados registros para este(a) pesquisado(a).
        </div>
      )}
    </TabContainer>
  );
};

export { TabContentPartnership };
