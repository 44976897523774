import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Pagination, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
import { render } from "react-dom";
import { LoadingSkeleton } from "../../../../../../common/components/loading";
import { SituacaoCadastralSintegra } from "../sintegra__sintegra_pj";

const TabContentSintegraPF = () => {
  const serviceKey = ServicesEnum.sintegra.name;
  const sectionKey = ServicesEnum.sintegra.sintegra_pf.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services
    ? state.data.target?.services[serviceKey]
    : null;
  const sectionData =
    serviceData?.sections && serviceData.sections[sectionKey]
      ? serviceData.sections[sectionKey]
      : null;

  useEffect(() => {
    if (
      serviceData?.dossier_id &&
      serviceData?.service_status === "completed" &&
      !sectionData?.data?.pagination?.current_page
    ) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const columns = [
    {
      title: "SINTEGRA",
      responsive: ["xs",],
      dataIndex: "domain",
      render: (_, record) => {
        return (<>
          <div><label>INSCRIÇÃO ESTADUAL</label>{record.inscricao_estadual}</div>
          <div><label>PRODUTOR RURAL</label>{record.produtor_rural}</div>
          <div><label>SITUAÇÃO CADASTRAL</label><SituacaoCadastralSintegra value={record.situacao_cadastral} record={record} /></div>
          <div><label>DATA SITUAÇÃO CADASTRAL</label>{record.situacao_cadastral_data}</div>
          <div><label>OBSERVAÇÕES SITUAÇÃO CADASTRAL</label>{record.situacao_cadastral_observacoes}</div>
          <div><label>ASSOSCIADOS</label>{record.associados}</div>
        </>)
      }
    },
    {
      title: "INSCRIÇÃO ESTADUAL",
      responsive: ["lg", "md"],
      dataIndex: "inscricao_estadual",
    },
    {
      title: "PRODUTOR RURAL",
      responsive: ["lg", "md"],
      dataIndex: "produtor_rural",
      render: (value) =>
        value?.toLocaleLowerCase() === "sim" ? " SIM" : " NÃO",
    },
    {
      title: "SITUAÇÃO CADASTRAL",
      responsive: ["lg", "md"],
      dataIndex: "situacao_cadastral",
      render: (value, record) => {
        return <SituacaoCadastralSintegra value={value} record={record} />
      },
    },
    {
      title: "DATA SITUAÇÃO CADASTRAL",
      responsive: ["lg", "md"],
      dataIndex: "situacao_cadastral_data",
    },
    {
      title: "OBSERVAÇÕES SITUAÇÃO CADASTRAL",
      responsive: ["lg", "md"],
      dataIndex: "situacao_cadastral_observacoes",
    },
    {
      title: "ASSOSCIADOS",
      responsive: ["lg", "md"],
      dataIndex: "associados",
    },
  ];

  function getValueByKey(key, obj) {
    return key.split(".").reduce((o, i) => (o ? o[i] : undefined), obj);
  }

  function formatAddress({ city, cep, logradouro, bairro, uf, numero }) {
    return `${logradouro}, ${numero ?? ""} ${bairro}. ${city} - ${uf}. ${
      cep ?? ""
    } `;
  }

  return (
    <TabContainer icon={<Icons icon={"sintegra"} />} title={"Sintegra"}>
      {sectionData?.status === StateStatus.loading ? (
        <LoadingSkeleton />
      ) : sectionData?.status === StateStatus.succeeded &&
        sectionData?.data?.pagination?.total_items > 0 ? (
        <>
          {sectionData?.data?.items?.map((data: any, idx) => {
            return (
              <Row key={idx}>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Row align={"middle"}>
                    <div>
                      <div
                        style={{
                          width: 0,
                          height: 0,
                          borderTop: "10px solid transparent",
                          borderBottom: "10px solid transparent",
                          borderLeft: "15px solid #007AFF",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      ></div>
                      <span style={{ fontWeight: "500", fontSize: "20px" }}>
                        {getValueByKey("nome_fantasia", data)}
                      </span>
                    </div>
                  </Row>
                  <br />
                  <Row>
                    {columns.map((item, idx) => (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <div className="service-label-value">
                          <label>{item?.title}</label>
                          {item?.render ? (
                            item?.render(
                              getValueByKey(item?.dataIndex, data),
                              data
                            )
                          ) : (
                            <div>
                              {getValueByKey(item?.dataIndex, data) ?? "-"}
                            </div>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Row>
                    <Col span={24}>
                      <div className="service-label-value">
                        <label>ENDEREÇO</label>
                        <div>
                          {formatAddress({
                            city: data.endereco_municipio,
                            cep: data.endereco_cep,
                            logradouro: data.endereco_logradouro,
                            bairro: data.endereco_bairro,
                            uf: data.endereco_uf,
                            numero: data.endereco_numero,
                          })}
                        </div>
                        <iframe
                          style={{
                            border: "none",
                            width: "320px",
                            height: "200px",
                          }}
                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBloQgYAwgxRmK4wPpBIlyI-u5dxL7DaJc&q=${formatAddress(
                            {
                              city: data.endereco_municipio,
                              cep: data.endereco_cep,
                              logradouro: data.endereco_logradouro,
                              bairro: data.endereco_bairro,
                              uf: data.endereco_uf,
                              numero: data.endereco_numero,
                            }
                          )}&zoom=16 allowfullscreen`}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <hr
                  style={{
                    color: "#7D7D7D",
                    width: "100%",
                    margin: "40px 0px",
                  }}
                />
              </Row>
            );
          })}
          <Row justify={"end"}>
            <Pagination
              showSizeChanger={false}
              current={sectionData?.data?.pagination?.current_page}
              pageSize={sectionData?.data?.pagination?.per_page}
              total={sectionData?.data?.pagination?.total_items}
              onChange={(page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              }}
            />
          </Row>
        </>
      ) : (
        <div style={{ textAlign: "center", width: "100%", marginTop: "15px" }}>
          Não foram encontrados registros para este(a) pesquisado(a).
        </div>
      )}
    </TabContainer>
  );
};

export { TabContentSintegraPF };
