
import React from "react";
import { Col, Row } from "antd";
import { TabContentGnews } from "../gnews";
import { TabContentSocialmedia } from "../socialmedia";
import { TabContentWhois } from "../whois";

const TabContentNoticiasMidiasSites = () => {

  return (
    <div>
      <Row gutter={[24, 24]}>
        {/* <Col span={24}>
          <TabContentGnews />
        </Col> */}
        <Col span={24}>
          <TabContentWhois />
        </Col>
        <Col span={24}>
          <TabContentSocialmedia />
        </Col>
      </Row>
    </div>
  );
};

export { TabContentNoticiasMidiasSites };
