
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import StateStatus from "../../../../utils/stateStatus";
import "./styles.scss";
import { Badge, Skeleton, Tabs} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { selectReportPageState } from "../report-page/slice";
import { TabContentVideoExplain } from "../target-page/tabs/video_explain";
import { TabContentFileAttachments } from "../target-page/tabs/file_attachments";
import { TabContentResumoDocs } from "../target-page/tabs/resumo_docs";

const BadgeNumber = ({ count, isLoading }) =>  {
  if (!count || count <= 0) return <Badge color="gray" size="small" count={0}/>
  // if (isLoading) return <Icons icon="company"/>
  return <Badge color="blue" size="small" count={count} overflowCount={999}/>
}

const DocPage = ({reportId}) => {
    const reportState = useSelector(selectReportPageState);
    const navigate = useNavigate()
    const location = useLocation()
    const [resumoTab, setResumoTab] = useState<React.JSX.Element>()
    const [activeTab, setActiveTab] = useState<string>()

    const report = reportState.data.report;

    useEffect(() => {
      if(reportState.data.report?.case_type === "smart_pro") {
        // TODO: show video presentation
      }
    }, [reportId])

    useEffect(() => {
      changeActiveTab()  
    }, [location.search, location.hash])

    
    useEffect(() => {
      if(reportState.status.getReport === StateStatus.loading) {
        changeActiveTab()
      }
    }, [reportState.status.getReport])

    const changeActiveTab = () => {
      const query = new URLSearchParams(location.search)
      const tab = query.get("tab")
      const defaultTab = "resumo"
      setActiveTab(tab ? tab.toString().toLowerCase() : defaultTab)
      window.scrollTo(0, 0);
    }
    
    const onChangeReportTab = () => {
      return Tabs
    }

    interface TabNew {
      key: string,
      label: ReactNode,
      order?: number | undefined,
      count: number | undefined | null,
      status?: 'loading' | 'completed' | 'idle' | 'released' | 'error' | string,
      can_render?: boolean | undefined,
      children: ReactNode,
    }

      const items: TabNew[] = useMemo(() => {
        if(reportState.status.getReport !== StateStatus.succeeded) return []

        setResumoTab(<TabContentResumoDocs />)
        
        const lst = [
          {
            key: "resumo",
            label: 'Resumo',
            order: 1,
            count: null,
            status: report?.status,
            can_render: true,
            children: resumoTab,
          },
          {
            key: "orientacoes",
            label: 'Orientações',
            order: 2,
            count: null,
            status: report?.status,
            can_render: true,
            children: <TabContentVideoExplain />,
          },
          {
            key: "attachments",
            label: 'Arquivos e anexos',
            order: 3,
            count: reportState.data.files?.report_files?.filter(x => x.status === "completed").length,
            status: report?.status,
            can_render: true,
            children: <TabContentFileAttachments />,
          },
        ]

        lst.sort((a, b) => {
          // Se ambos objetos têm a propriedade "order", compare por "order"
          if (a.order !== undefined && b.order !== undefined) {
            return a.order - b.order;
          }
          // Se apenas um dos objetos tem "order", ele vem primeiro
          if (a.order !== undefined) {
            return -1;
          }
          if (b.order !== undefined) {
            return 1;
          }
          // Se nenhum tem "order", compare por "count" (maior primeiro)
          return (b.count ?? 0) - (a.count ?? 0);
        });

        return lst;
    }, [
        reportState.status.getReport, 
        reportState.data.files,
    ]);

    return (
        <div className="doc-view-page">
            {reportState.status.getReport === StateStatus.succeeded? (
              <>
                <Tabs 
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                  onTabClick={(tabKey) => {
                    setActiveTab(tabKey)
                    navigate(`${location.pathname}?tab=${tabKey}`)
                  }}
                  // @ts-ignore
                  items={items.map((x, key) => (x.can_render ? {
                    label: <>{x.label} {x.count !== null ? <BadgeNumber count={x.count} isLoading={x.status === "loading"} /> : null}</>, 
                    children: x.children, 
                    key: x.key
                  } : {}))} 
                  onChange={onChangeReportTab} 
                />
                <div style={{display: "none"}}>{resumoTab}</div>
              </>
            ) : reportState.status.getReport === StateStatus.loading ? (
              <div>
                  <div className="loading-text">
                      <Skeleton active />
                  </div>
              </div>
            ) : <></>}
        </div>
    );
}


export { DocPage }
