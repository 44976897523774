
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import localeValues from "antd/locale/pt_BR";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";

const TabContentProtestos = () => {
  const serviceKey = ServicesEnum.boavista.name;
  const sectionKey = ServicesEnum.boavista.protestos.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);


  const columns = [
    {
      title: "PROTESTOS",
      responsive: ["xs",],
      dataIndex: "domain",
      render: (_, record) => {
        return (<>
          <div><label>ESTADO</label>{record.uf}</div>
          <div><label>CIDADE</label>{record.cidade}</div>
          <div><label>VENCIMENTO</label>{record.vencimento}</div>
          <div><label>DATA OCORRÊNCIA</label>{record.data_ocorrencia}</div>
          <div><label>VALOR</label>{record.valor}</div>
        </>)
      }
    },
    {
      title: "ESTADO",
      responsive: ["lg", "md"],
      dataIndex: "uf",
    },
    {
      title: "CIDADE",
      responsive: ["lg", "md"],
      dataIndex: "cidade",
    },
    {
      title: "VENCIMENTO",
      responsive: ["lg", "md"],
      dataIndex: "vencimento",
    },
    {
      title: "DATA OCORRÊNCIA",
      responsive: ["lg", "md"],
      dataIndex: "data_ocorrencia",
    },
    {
      title: "VALOR",
      responsive: ["lg", "md"],
      dataIndex: "valor",
    },
  ];

  return (
    <TabContainer
      icon={<Icons icon={"protesto"} />}
      title={"Títulos protestados (Fonte BoaVista)"}
    >
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de protestos</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            // @ts-ignore
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui protestos'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentProtestos };
