
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDataThunk, selectTargetPageState } from "../../slice";
import { Col, Row, Table } from "antd";
import StateStatus from "../../../../../../utils/stateStatus";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { ServicesEnum } from "../../../../../../utils/services";
import "./styles.scss"
import { formatCurrency } from "../../../../../../utils/formatters";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const MAP_SECTIONS = {
  processos_em_tramitacao_por_cpf_cnpj:  {title: "Processos em tramitação por CPF/CNPJ"},
  processos_em_tramitacao_por_nome: {title: "Processos em tramitação por nome"},
  processos_inativos_por_nome: {title: 'Prcessos arquivados por nome'},
  processos_inativos_por_cpf_cnpj: {title: 'Processos arquivados por CPF/CNPJ'},
  processos_para_aquisicao_copia: {title: 'Processos relevantes'},
}

interface TabContentProcessosProps {
  sectionKey: string, 
}

const PoleBrand = ({value, pole}) => {
  let color = "black"

  if (pole === 'PASSIVO') {
    color = "red"
  }
  
  if (pole === 'ATIVO') {
    color = "green"
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{color: value && value > 0 ? color : "black"}}>{value}</div>
      <div style={{color, fontSize: '10px'}}>{pole.toUpperCase()}</div>
    </div>
  )
}

const TabContentProcessos: FunctionComponent<TabContentProcessosProps> = ({ sectionKey }) => {
  const serviceKey = ServicesEnum.processos.name;
  const state = useSelector(selectTargetPageState);
  const dispatch = useDispatch();
  const location = useLocation();

  const serviceData = state.data.target?.services ? state.data.target?.services[serviceKey] : null;
  const sectionData = serviceData?.sections && serviceData.sections[sectionKey] ? serviceData.sections[sectionKey] : null;

  useEffect(() => {
    if (serviceData?.dossier_id && serviceData?.service_status === "completed" && !sectionData?.data?.pagination?.current_page) {
      dispatch(
        getServiceDataThunk({
          service: serviceKey,
          section: sectionKey,
          dossierId: serviceData?.dossier_id,
          page: 1,
        })
      );
    }
  }, [serviceData?.dossier_id]);

  const buildLink = (tabKey) => {
    return `${location.pathname}?tab=${tabKey}`
  }
  

  const columns = [
    {
      title: "Processo",
      responsive: ["xs", "sm", "lg", "md"],
      dataIndex: "distribution_date",
      render: (_, record) => {
        return <div>
          <p style={{fontSize: '14px', fontWeight: 500}}>{record.number}</p>
          <p><label>Distribuição</label> {record.distribution_date}</p>
          <p><label>Órgão Julgador</label> {record.court}</p>
          <p><label>Área do Direito</label> {record.kind}</p>
          <div className="hide-on-full-screen">
            <p><label>Valor Declarado</label> <PoleBrand value={record.declared_value} pole={record.pole}/></p>
            <p><label>Natureza</label> {record.nature}</p>
            <p><label>Assunto</label> {record.subject}</p>

            {record?.probability_description?.includes("Baixa") ? (
              <div><label>Probabilidade de ser da pessoa pesquisada</label> {record.probability_description}</div>
            ) : record?.probability_description?.includes("Alta") ? (
              <div><label>Probabilidade de ser da pessoa pesquisada</label> {record.probability_description}</div>
            ) : (
              <div><label>Probabilidade de ser da pessoa pesquisada</label> {record.probability_description}</div>
            )}

            {record?.has_target_and_others_polo_passivo ? (
              <div>
                <label>Figuram um pesquisado principal e outra(s) pessoa(s) no polo passivo do processo</label>
                <div style={{color: '#007AFF'}}>SIM</div>
              </div>
              
            ) : (
              <div>
                <label>Figuram um pesquisado principal e outra(s) pessoa(s) no polo passivo do processo</label>
                <div>NÃO</div>
              </div>
            )}

            <div>
              <label>Pessoas relacionadas</label>
              {record?.related_people?.map((person) => (
                <p>
                  {person.is_target ? (
                    <span style={{color: '#009BED', fontWeight: 500}}>
                      {person.name} ({person.role})
                    </span>
                  ) : (
                    <>
                      {person.name} ({person.role})
                    </>
                  )}
                </p>
              ))}
            </div>
          </div>
        </div>
      },
      width: '250px'
    },
    {
      title: "Valor Declarado",
      responsive: ["lg", "md"],
      dataIndex: "declared_value",
      width: '150px',
      render: (value, record) => {
        return <PoleBrand value={value} pole={record.pole}/>
      }
    },
    {
      title: "Natureza",
      responsive: ["lg", "md"],
      dataIndex: "nature",
    },
    {
      title: "Assunto",
      responsive: ["lg", "md"],
      dataIndex: "subject",
    },
    {
      title: 'Probabilidade de ser da pessoa pesquisada',
      responsive: ["lg", "md"],
      dataIndex: "probability_description",
      render: (value) => {
       if (value.includes("Baixa")) {
        return <span style={{color: '#DCBE14'}}>{value}</span>
       }
       if (value.includes("Alta")) {
        return <span style={{color: '#DC6E14'}}>{value}</span>
       }

       return value
      }
    },
    {
      title: "Figuram um pesquisado principal e outra(s) pessoa(s) no polo passivo do processo",
      responsive: ["lg", "md"],
      dataIndex: "has_target_and_others_polo_passivo",
      render: (value) => {
        if (value) {
          return <span style={{color: '#007AFF'}}>SIM</span>
        }

        return <span>NÃO</span>
      }
    },
    {
      title: "Pessoas Relacionadas",
      responsive: ["lg", "md"],
      dataIndex: "related_people",
      width: "25%",
      render: (value, _) => {
        return (
          <div>
            {value.map((person) => (
              <p>
                {person.is_target ? (
                  <span style={{color: '#009BED', fontWeight: 500}}>
                    {person.name} ({person.role})
                  </span>
                ) : (
                  <>
                    {person.name} ({person.role})
                  </>
                )}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  const by = sectionKey?.includes("cpf_cnpj") ? "cpf_cnpj" : "nome"
  const counterKey = sectionKey?.includes("inativo") ? "processos_inativos" : "processos_ativos"
  const counter  = serviceData?.counters?.data ? serviceData?.counters?.data[counterKey] : null
  const rowSpanPoloPassivo = counter ? (by === "nome" ? 3 : 1) : 1
  const rowSpanPoloAtivo = counter ?  (by === "nome" ? 3 : 1) : 1
  const rowSpanOthers = counter ? (by === "nome" ? 3 : 1) : 1

  const tableResumo = (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Resumo processos</th>
          <th>Quantidade</th>
          <th>Valor total</th>
        </tr>
      </thead>
      {counter ? (
      <tbody>
        {/** Polo Passivo  */}
        <tr>
            <th rowSpan={rowSpanPoloPassivo}>Polo Passivo</th>
            <td>Total no Polo Passivo</td>
            <td>{counter[`qtd_por_${by}`]?.polo_passivo}</td>
            <td>{formatCurrency(counter[`valor_por_${by}`]?.polo_passivo)}</td>
        </tr>
        {/* {Object.keys(counter[`by_kind_por_${by}`]?.polo_passivo ?? {}).map((key, idx) => {
            return (
              <tr key={idx}>
                  <td>Área do direito - {key}</td>
                  <td>{counter[`by_kind_por_${by}`]?.polo_passivo[key].qtd}</td>
                  <td>{formatCurrency(counter[`by_kind_por_${by}`]?.polo_passivo[key].valor)}</td>
              </tr>
            );
        })} */}
        {by === "nome" ? (<>
        <tr>
            <td>Baixa Probabilidade</td>
            {by === "nome" ? (<>
              <td>{counter?.qtd_baixa_probabilidade?.polo_passivo}</td>
              <td>{formatCurrency(counter?.valor_baixa_probabilidade?.polo_passivo)}</td>
            </>) : (<>
              <td>0</td>
              <td>R$ 0</td>
            </>)}
        </tr>
        <tr>
            <td>Alta Probabilidade</td>
            {by === "nome" ? (<>
              <td>{counter?.qtd_alta_probabilidade?.polo_passivo}</td>
              <td>{formatCurrency(counter?.valor_alta_probabilidade?.polo_passivo)}</td>
            </>) : (<>
              <td>0</td>
              <td>R$ 0</td>
            </>)}
        </tr></>) : null}
        {/* <tr>
            <td>Confirmados</td>
            {by === "cpf_cnpj" ? (<>
              <td>{counter?.qtd_confirmado?.polo_passivo}</td>
              <td>{formatCurrency(counter?.valor_confirmado?.polo_passivo)}</td>
            </>) : (<>
              <td>0</td>
              <td>R$ 0</td>
            </>)}
        </tr> */}
        {/** Polo Ativo  */}
        <tr>
            <th rowSpan={rowSpanPoloAtivo}>Polo Ativo</th>
            <td>Total no Polo Ativo</td>
            <td>{counter[`qtd_por_${by}`]?.polo_ativo}</td>
            <td>{formatCurrency(counter[`valor_por_${by}`]?.polo_ativo)}</td>
        </tr>
        {/* {Object.keys(counter[`by_kind_por_${by}`]?.polo_ativo ?? {}).map((key, idx) => {
            return (
              <tr key={idx}>
                  <td>Área do direito - {key}</td>
                  <td>{counter[`by_kind_por_${by}`]?.polo_ativo[key].qtd}</td>
                  <td>{formatCurrency(counter[`by_kind_por_${by}`]?.polo_ativo[key].valor)}</td>
              </tr>
            );
        })} */}
        {by === "nome" ? (<>
          <tr>
              <td>Baixa Probabilidade</td>
              <td>{counter?.qtd_baixa_probabilidade?.polo_ativo}</td>
              <td>{formatCurrency(counter?.valor_baixa_probabilidade?.polo_ativo)}</td>
          </tr>
          <tr>
              <td>Alta Probabilidade</td>
              <td>{counter?.qtd_alta_probabilidade?.polo_ativo}</td>
              <td>{formatCurrency(counter?.valor_alta_probabilidade?.polo_ativo)}</td>
          </tr>
        </>) : null}
        {/* <tr>
            <td>Confirmados</td>
            {by === "cpf_cnpj" ? (<>
              <td>{counter?.qtd_confirmado?.polo_ativo}</td>
            <td>{formatCurrency(counter?.valor_confirmado?.polo_ativo)}</td>
            </>) : (<>
              <td>0</td>
              <td>R$ 0</td>
            </>)}
        </tr> */}
        {/** Indiretos  */}
        <tr>
            <th rowSpan={rowSpanOthers}>Indiretos</th>
            <td>Total Indiretos</td>
            <td>{(counter[`qtd_por_${by}`]?.incidente ?? 0) + (counter[`qtd_por_${by}`]?.indireto ?? 0)}</td>
            <td>{formatCurrency((counter[`valor_por_${by}`]?.incidente ?? 0) + (counter[`valor_por_${by}`]?.indireto ?? 0))}</td>
        </tr>
        {/* {Object.keys(counter[`by_kind_por_${by}`]?.others ?? {}).map((key, idx) => {
            return (
              <tr key={idx}>
                  <td>Área do direito - {key}</td>
                  <td>{counter[`by_kind_por_${by}`]?.others[key].qtd}</td>
                  <td>{formatCurrency(counter[`by_kind_por_${by}`]?.others[key].valor)}</td>
              </tr>
            );
        })} */}
        {by === "nome" ? (<>
        <tr>
            <td>Baixa Probabilidade</td>
            <td>{(counter?.qtd_baixa_probabilidade?.incidente ?? 0) + (counter?.qtd_baixa_probabilidade?.indireto ?? 0)}</td>
            <td>{formatCurrency((counter?.valor_baixa_probabilidade?.incidente ?? 0) + (counter?.valor_baixa_probabilidade?.indireto ?? 0))}</td>
        </tr>
        <tr>
            <td>Alta Probabilidade</td>
            <td>{(counter?.qtd_alta_probabilidade?.incidente ?? 0) + (counter?.qtd_alta_probabilidade?.indireto ?? 0)}</td>
            <td>{formatCurrency((counter?.valor_alta_probabilidade?.incidente ?? 0) + (counter?.valor_alta_probabilidade?.indireto ?? 0))}</td>
        </tr>
        </>) : null}
        {/* <tr>
            <td>Confirmados</td>
            {by === "cpf_cnpj" ? (<>
              <td>{(counter?.qtd_confirmado?.incidente ?? 0) + (counter?.qtd_confirmado?.indireto ?? 0)}</td>
              <td>{formatCurrency((counter?.valor_confirmado?.incidente ?? 0) + (counter?.valor_confirmado?.indireto ?? 0))}</td>
            </>) : (<>
              <td>0</td>
              <td>R$ 0</td>
            </>)}
        </tr> */}
        <tr>
           <th colSpan={2} style={{textAlign: "center"}}>Diferença entre total de ativos e passivos</th>
           <td colSpan={2} style={{textAlign: "center"}}>{formatCurrency((counter[`valor_por_${by}`]?.polo_ativo ?? 0) - (counter[`valor_por_${by}`]?.polo_passivo ?? 0))}</td>
        </tr>
      </tbody>
      ) : (
        <tbody>
          <tr><td colSpan={4} style={{textAlign: "center", color: "rgba(0, 0, 0, 0.25)"}}>Resumo de processos não calculado.</td></tr>
        </tbody>
      )}
    </table>
  )

  let total_processes_counter = serviceData?.counters ? (serviceData?.counters[`total_processes_by_${by}_counter`] ?? 0) : 0
  const fetched_processes_counter = serviceData?.counters ? (serviceData?.counters[`fetched_processes_by_${by}_counter`] ?? 0) : 0
  total_processes_counter = fetched_processes_counter > total_processes_counter ? fetched_processes_counter : total_processes_counter

  return (
    <TabContainer
      icon={<Icons icon={"processo"} />}
      title={by === "nome" ? MAP_SECTIONS[sectionKey]?.title : MAP_SECTIONS[sectionKey]?.title + " (Processos confirmados)"}
    >
      {sectionKey !== "processos_para_aquisicao_copia" ? (
        <Row justify={"center"} className="resumo-processos">
          {tableResumo}
        </Row>
      ) : null}
      <Row>
        <Col span={24}>
          <div className="service-label-value inline">
            <label>Total de processos</label>
            <div>{sectionData?.data?.pagination?.total_items ?? "-"}</div>
          </div>
        </Col>
        {sectionKey !== "processos_para_aquisicao_copia" ? (
          <Col span={24}>
            <div className="info-message">
              <p>
                {total_processes_counter && fetched_processes_counter && total_processes_counter > fetched_processes_counter && total_processes_counter > 1000 ? (<>
                  Você está visualizando os primeiros <span className="highcolor">{fetched_processes_counter} processos</span> desse pesquisado. Para ter acesso a todos os <span className="highcolor">{total_processes_counter} processos</span>, por favor, <Link to={"https://web.whatsapp.com/send?phone=5511948889788"} target="_blank">clique aqui</Link> para entrar em contato. 
                </>) : null}
                {" "}Para obter maiores informações dos processos abaixo, exporte o arquivo 
                em formato excel na aba <Link to={buildLink("attachments")} onClick={() => window.scrollTo(0, 0)} target="_self" >arquivos e anexos</Link>.
              </p>
            </div>
          </Col>
        ) : null}
        <Col span={24}>
          <Table
            rowKey={(record) => record.id}
            // @ts-ignore
            columns={columns}
            dataSource={sectionData?.data?.items ?? []}
            loading={sectionData?.status === StateStatus.loading}
          
            pagination={{
              showSizeChanger: false,
              current: sectionData?.data?.pagination?.current_page,
              pageSize: sectionData?.data?.pagination?.per_page,
              total: sectionData?.data?.pagination?.total_items,
              onChange: (page, pageSize) => {
                dispatch(
                  getServiceDataThunk({
                    service: serviceKey,
                    dossierId: serviceData.dossier_id,
                    section: sectionKey,
                    page: page,
                  })
                );
              },
            }}
            locale={{emptyText: 'Este pesquisado(a) não possui processos'}}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};

export { TabContentProcessos };
