import React, { FunctionComponent, useEffect } from "react";
import { Row, Modal, Form, message, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uploadNfsThunk, getReportStatusThunk, resetStatus, selectReportStatusState, listNfseThunk } from "../../slices";
import { BlueSquareButton, BlueSquareOulinedButton } from "../../../../common/components/button";
import StateStatus from "../../../../utils/stateStatus";
import Input from "../../../../common/components/input";
import { ReportStatusType } from "../../interfaces";

interface ModalUploadNfsReportInterface {
  report: ReportStatusType,
  onCancel: () => void,
  onSuccess: () => void,
}

const ModalUploadNfsReport: FunctionComponent<ModalUploadNfsReportInterface> = ({report, onCancel, onSuccess}) => {
  const slice = useSelector(selectReportStatusState);
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  
  useEffect(() => {
    if(slice.status.uploadNfs === StateStatus.succeeded) {
      message.success("Nota fiscal enviada com sucesso.")
      dispatch(resetStatus())
      dispatch(getReportStatusThunk(report.id));
      dispatch(listNfseThunk(report.id));
      onSuccess()
    }

    if(slice.status.uploadNfs === StateStatus.failed) {
      message.error("Não foi possivel enviar a nota fiscal.")
      dispatch(resetStatus())
    }
  }, [slice.status.uploadNfs])


  return (
    <>
    <Modal
      className="upload-nfs-modal"
      title={"Enviar nota fiscal"}
      open={true}
      onCancel={onCancel}
      footer={[
        <Row justify="space-between" style={{ marginTop: "30px" }}>
          <BlueSquareOulinedButton
            onClick={onCancel}>
            Cancelar
          </BlueSquareOulinedButton>

          <BlueSquareButton
            onClick={() => form.submit()}
            isLoading={slice.status.uploadNfs === StateStatus.loading}
            disabled={slice.status.uploadNfs === StateStatus.loading}
          >
            Salvar
          </BlueSquareButton>
        </Row>,
      ]}
      width={"600px"}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={(values) => {
          values.report_id = report.id
          dispatch(uploadNfsThunk(values))
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Link"
              name="link"
            >
              <Input placeholder="Link da nfs-e"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Modal>
    </>
  );
};


export default ModalUploadNfsReport;