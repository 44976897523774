
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
// 
import Input from "../input"
// 
import "./index.scss"
import moment from "moment"
import { InputProps } from "antd"

interface FormatsType {
	condition: (e?: string) => boolean
	format: string,
}

interface MaskedInputProps {
	mask: string,
	formats: FormatsType[],
	initialValue?: string | undefined | null,
	isReadOnly?: boolean | undefined,
	onChange?: (e: string) => void,
	placeholder?: string | undefined
	onBlur?: (e: any) => void | undefined,
	value?: string | number
}

const MaskedInput: React.FunctionComponent<MaskedInputProps> = ({
	placeholder,
	onBlur,
	mask,
	formats,
	onChange,
	initialValue,
	isReadOnly,
	value,
	...props
}) => {
	const [input, setInput] = useState({selectionEnd: 0, selectionStart: 0})
	const [cursor, setCursor] = useState(0)
	const [inputValue, setInputValue] = useState<string>("")

	useEffect(() => {
		setInput({ ...input })
		// setInput({ ...input })
	}, [])

	useEffect(() => {
		if (input) {
			input.selectionEnd = cursor
			input.selectionStart = cursor
		}
	}, [cursor, input])

	const onPressBackspace = (event) => {
		if (event.code === "Backspace") {
			const allNumbers = "0123456789"
			const { value } = event.target
			const charToEreaseIndex = event.target.selectionStart - 1
			const charToErease = value[charToEreaseIndex]

			if (!allNumbers.includes(charToErease)) {
				event.target.selectionEnd = charToEreaseIndex
				event.target.selectionStart = charToEreaseIndex
			}

			if (onChange) onChange(event.target.value || null)
		}

	}

	const customFormatter = (target) => {
		const cleanedValue: string =
			target.value !== undefined ? target.value.replace(/\D/g, "") : ""

		formats.some((item) => {
			if (item.condition(cleanedValue)) {
				let valueIndex = 0
				let finalValue = ""

				item.format.split("").forEach((char) => {
					if (char === "#") {
						finalValue += cleanedValue[valueIndex] || mask
						valueIndex += 1
					} else {
						finalValue += char
					}
				})
				setInput(target)
				setInputValue(finalValue)
				setCursor(finalValue.indexOf(mask))

				if (onChange) {
					if (item.format === "##/##/####") {
						onChange(moment(finalValue, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm"))
					} else {
						onChange(finalValue)
					}
				}
				return true
			}
			return false
		})
	}

	return (
		<Input
			{...props}
			// onKeyDown={onPressBackspace} # TODO: test after tsc-migrate
			value={inputValue || value}
			onInput={(event) => {
				customFormatter(event.target)
			}}
			isReadOnly={isReadOnly}
			placeholder={placeholder ?? ""}
			onBlur={onBlur}
		/>
	)
}


export default MaskedInput
