import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTargetPageState } from "../../slice";
import { Button, Col, message, Row, Tooltip } from "antd";
import Icons from "../../../../../../common/components/Icons";
import { TabContainer } from "../../components/tab-container";
import { selectReportPageState } from "../../../report-page/slice";
import { urlToNewTabLink } from "../../../../../../utils/files";
import "./styles.scss";
import { buildFileIconStatus } from "../../../report-page/components/card-report-files";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";


const TabContentVideoExplain = () => {
  const state = useSelector(selectTargetPageState);
  const reportState = useSelector(selectReportPageState);
  const location = useLocation()

  useEffect(() => {}, []);

  const copyPasswordVideo = () => {
    if(reportState.data?.report?.video_description_password) {
      navigator.clipboard.writeText(reportState.data?.report?.video_description_password);
      message.success("Senha copiada para área de trasnferência");
    }
  };

  const buildLink = (tabKey) => {
    return `${location.pathname}?tab=${tabKey}`
  }

  return (
      <TabContainer
        icon={<Icons icon="orientations" />}
        title={"Orientações"}
      >
                <Row
                  justify="space-between"
                  gutter={[24, 24]}
                  className="orientations-section"
                >
                  <Col xs={24} sm={24} md={12}>
                    <ol>
                      {reportState.data.report?.video_description_embed ? (
                        !reportState.data.report?.video_description_password ? (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos assistir ao vídeo do parecer
                            técnico anexado em seguida.
                          </li>
                        ) : (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos assistir ao vídeo do parecer
                            técnico anexado em seguida. Insira a senha{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={copyPasswordVideo}
                            >
                              {
                                reportState.data.report
                                  ?.video_description_password
                              }
                            </span>
                            <span className="copy-icon">
                              <Icons icon="copy" onClick={copyPasswordVideo} />
                            </span>{" "}
                            para iniciar o vídeo;
                          </li>
                        )
                      ) : reportState.data.report?.video_description_link ? (
                        reportState.data.report?.video_description_password ? (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos acessar o{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={() => {
                                window.open(
                                  reportState.data.report
                                    ?.video_description_link,
                                  "__blank"
                                );
                              }}
                            >
                              {" "}
                              link do parecer técnico{" "}
                            </span>{" "}
                            para assistir ao vídeo de apresentação. Insira a
                            senha{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={copyPasswordVideo}
                            >
                              {
                                reportState.data.report
                                  ?.video_description_password
                              }
                            </span>
                            <span className="copy-icon">
                              <Icons icon="copy" onClick={copyPasswordVideo} />
                            </span>{" "}
                            para inciar o vídeo;
                          </li>
                        ) : (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos acessar o{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={() => {
                                window.open(
                                  reportState.data.report
                                    ?.video_description_link,
                                  "__blank"
                                );
                              }}
                            >
                              link do parecer técnico
                            </span>{" "}
                            para assistir ao vídeo de apresentação;
                          </li>
                        )
                      ) : null}
                      {!["docs", "mapa_calor"].includes(reportState.data.report?.case_type ?? "") ? (
                      <li>
                        Navegue em todos os pesquisados disponíveis neste REPORT
                        selecionando acima o pesquisado(a) a visualizar;
                      </li> ) : null}
                      <li>
                        Obtenha todas as pesquisas prontas em formato EXCEL
                        clicando em{" "}
                        <span className="text-highlight">
                          <Link to={buildLink("attachments")} onClick={() => {
                              window.scrollTo(0, 0);
                          }} target="_self" >Arquivos e anexos</Link>
                        </span>.
                      </li>
                    </ol>
                  </Col>
                  {reportState.data.report?.video_description_embed ? (
                    <Col xs={24} sm={24} md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            reportState.data.report?.video_description_embed,
                        }}
                      ></div>
                    </Col>
                  ) : null}
                </Row>
      </TabContainer>
  );
};

export { TabContentVideoExplain };
