import React, { FunctionComponent, ReactNode } from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"
import { Input as InputAntd } from "antd"
import MaskInput from "../mask-input"

interface InputProps {
	key?: any,
	label?: ReactNode,
	value?: string | number,
	type?: string,
	disabled?: boolean | undefined,
	placeholder?: string | undefined,
	maxLength?: number,
	minLength?: number,
	onChange?: (e: any) => void,
	onInput?: (e: any) => void,
	onBlur?: (e: any) => void | undefined,
	isBlockPaste?: boolean | undefined
	isReadOnly?: boolean | undefined
	formatter?: (e: any) => any,
	style?: React.CSSProperties,
}


const Input: FunctionComponent<InputProps> = ({
	type,
	placeholder,
	value,
	maxLength,
	minLength,
	onChange,
	onInput,
	onBlur,
	isBlockPaste,
	isReadOnly,
	style,
	...props
}) => (
	<input
		{...props}
		// 
		className="simple-input"
		style={style}
		//
		type={type}
		value={value}
		placeholder={placeholder}
		//
		onBlur={onBlur}
		onInput={onInput}
		onChange={onChange}
		onPaste={(e) => {
			if (isBlockPaste)
				e.preventDefault()
			return false
		}}
		onCopy={(e) => {
			if (isBlockPaste)
				e.preventDefault()
			return false
		}}
		// 
		autoComplete={isBlockPaste ? "new-email" : undefined}
		// 
		maxLength={maxLength}
		minLength={minLength}
		readOnly={isReadOnly}
	/>
)

const InputPassword = ({ ...props }) => {
	return <InputAntd.Password className="simple-input" {...props} />
}


const InputPhone = ({ ...props }) => {
	// const prefixSelector = (
	// 	<Form.Item name="phone_prefix" initialValue={"55"} noStyle>
	// 		<AntSelect style={{ width: 100 }}>
	// 			<AntSelect.Option value="55">+55</AntSelect.Option>
	// 			<AntSelect.Option value="87">+87</AntSelect.Option>
	// 		</AntSelect>
	// 	</Form.Item>
	// );


	return <MaskInput mask={
		'(00) 00000-0000'
	} {...props}/>
}


export { InputPassword, InputPhone }
export default Input
