
import React from "react";
import { Col, Row } from "antd";
import { ServicesEnum } from "../../../../../../utils/services";
import { TabContentProcessos } from "../processos";

const TabContentProcessosTramitacao = () => {

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <TabContentProcessos sectionKey={ServicesEnum.processos.processos_em_tramitacao_por_cpf_cnpj.name} />
        </Col>
        <Col span={24}>
          <TabContentProcessos sectionKey={ServicesEnum.processos.processos_em_tramitacao_por_nome.name} />
        </Col>
      </Row>
    </div>
  );
};

export { TabContentProcessosTramitacao };
