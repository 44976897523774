
import React from "react";
import { Col, Row } from "antd";
import { TabContentMarcas } from "../inpi__marcas";
import { TabContentPatentes } from "../inpi__patentes";

const TabContentMarcasPatentes = () => {

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <TabContentMarcas  />
        </Col>
        <Col span={24}>
          <TabContentPatentes />
        </Col>
      </Row>
    </div>
  );
};

export { TabContentMarcasPatentes };
