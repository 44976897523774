import { Col, Row } from "antd"
import React, { FunctionComponent, ReactNode } from "react"
import "./styles.scss"

interface TabContainerProps {
    icon?: ReactNode,
    title?: ReactNode,
    children?: ReactNode,
    className?: string | undefined,
} 

const TabContainer: FunctionComponent<TabContainerProps> = ({icon,  title, children, className}) => {
    return (
        <div className={className}>
            {title || icon ? (
            <div className="section-title">
                <span>{icon}</span>
                <span>{title}</span>
            </div>) : null}
            <Row className="section-content">
                <Col span={24}>{children}</Col>
            </Row>
        </div>
    )
}

export { TabContainer }